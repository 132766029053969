import * as types from "../../mutation-types"
import VuexHelpers from "../../../helpers/vuex_helpers"
import axios from "axios"
import jsonApii from "@/helpers/json_apii"
import api from "@/api"


// initial state
const state = {
  quizzesAttemptSummaries: [],
}

// getters
const getters = {
  quizzesAttemptSummaries: state =>  state.quizzesAttemptSummaries,
  quizzesAttemptSummaryById: state => id => state.quizzesAttemptSummaries.find(s => s.id === id),
}

// actions
const actions = {
  fetchQuizzesAttemptSummaries({ commit }, { filter, pagination, sort, search } = { filter: {}, pagination: {}, sort: {}, search: {} }){
    return axios.get(
      api.v1.quizzesAttemptSummariesUrl(), {
        params: {
          filter,
          pagination,
          sort,
          search,
        },
        paramsSerializer: jsonApii.toFilters,
      }
    ).then( response => {
      commit(types.CREATE_OR_UPDATE_QUIZZES_ATTEMPT_SUMMARIES, jsonApii.getData(response.data))
      commit(
        types.UPDATE_QUIZZES_TAGS,
        jsonApii.getIncluded(response.data,"quizzes_tag"),
        { root: true }
      )
    })
  },
  fetchQuizzesAttemptSummary({ commit }, id) {
    return axios
      .get(api.v1.quizzesAttemptSummaryUrl(id))
      .then( response => {
        commit(types.CREATE_OR_UPDATE_QUIZZES_ATTEMPT_SUMMARY, jsonApii.getData(response.data))

        commit(
          types.UPDATE_ANSWERS_NEW,
          jsonApii.getIncluded(response.data,"answer_student"),
          { root: true }
        )
      })
  },
  updateQuizzesAttemptSummary({ commit }, quizzesAttemptSummary ) {
    return axios
      .patch(quizzesAttemptSummary.links.self, quizzesAttemptSummary)
      .then( response => {
        commit(types.CREATE_OR_UPDATE_QUIZZES_ATTEMPT_SUMMARY, jsonApii.getData(response.data))
      })
  },
  startQuizzesAttemptSummary({ commit,dispatch }, quizzesAttemptSummary ) {
    commit(types.QUIZ_INSTANCE_LOADING, true)

    return axios
      .post(quizzesAttemptSummary.links.start)
      .then( response => {
        const attemptSummary = jsonApii.getData(response.data)
        const instance = jsonApii.getIncluded(response.data, "instance")[0]

        commit(types.CREATE_OR_UPDATE_QUIZZES_ATTEMPT_SUMMARY, attemptSummary)
        commit(types.CREATE_OR_UPDATE_QUIZ_INSTANCE, instance, { root: true })

        // Dorian - 2024-07-30
        // Time offset should be handled with a global clock sync with the server
        commit(types.QUIZ_INSTANCE_TIME_OFFSET_UPDATE, attemptSummary?.date_time_utc_ms)

        commit(
          types.UPDATE_ANSWERS_NEW,
          jsonApii.getIncluded(response.data,"answer_student"),
          { root: true }
        )
        dispatch("getAnswersFromLocalStorage", instance)

        commit(types.QUIZ_INSTANCE_LOADING, false)

        // TODO: 2024-10-17 Remove next line after deployment
        jsonApii.getIncluded(response.data, "quizzes_document").forEach( document => {
          commit(types.UPDATE_QUIZZES_DOCUMENT, document, { root: true })
        })

        instance?.quiz_assets?.documents.forEach( document => {
          commit(types.UPDATE_QUIZZES_DOCUMENT, document.attributes, { root: true })
        })

        // TODO: 2024-10-17 Remove next line after deployment
        jsonApii.getIncluded(response.data, "questions_tag").forEach( question_tag => {
          commit(types.CREATE_OR_UPDATE_QUESTION_TAG, question_tag, { root: true })
        })

        instance?.quiz_assets?.visible_questions_tags.forEach( tag => {
          commit(types.CREATE_OR_UPDATE_QUESTION_TAG, tag, { root: true })
        })

        // TODO: 2024-10-17 Remove next line after deployment
        jsonApii.getIncluded(response.data, "tags_category").forEach( tag_category => {
          commit(types.CREATE_OR_UPDATE_QUESTION_TAGS_CATEGORY, tag_category, { root: true })
        })
        instance?.quiz_assets?.visibles_tags_categories.forEach( tag_category => {
          commit(types.CREATE_OR_UPDATE_QUESTION_TAGS_CATEGORY, tag_category, { root: true })
        })
      })
  },
  closeQuizzesAttemptSummary({ commit }, quizzesAttemptSummary ) {
    return axios
      .post(quizzesAttemptSummary.links.close)
      .then( response => {
        commit(types.CREATE_OR_UPDATE_QUIZZES_ATTEMPT_SUMMARY, jsonApii.getData(response.data))
      })
  },
}

// mutations
const mutations = {
  [types.CREATE_OR_UPDATE_QUIZZES_ATTEMPT_SUMMARIES]({ quizzesAttemptSummaries }, sessions){
    sessions.forEach(
      session => VuexHelpers.createOrUpdate(quizzesAttemptSummaries, session)
    )
  },
  [types.CREATE_OR_UPDATE_QUIZZES_ATTEMPT_SUMMARY]({ quizzesAttemptSummaries }, session){
    VuexHelpers.createOrUpdate(quizzesAttemptSummaries, session)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
