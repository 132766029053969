import Vue from "vue"
import Vuex from "vuex"
import * as actions from "./actions"
import * as getters from "./getters"
import currentUser from "./modules/current_user"
import quizzes from "./modules/quizzes"
import quizDraft from "./modules/quizzes/draft/drafts"
import scores from "./modules/quizzes/scores"
import users from "./modules/users"
import groups from "./modules/groups"
import questionsStats from "./modules/quizzes/questions_stats"
import scannedSheets from "./modules/quizzes/scanned_sheets"
import scans from "./modules/quizzes/scans"
import answers from "./modules/quizzes/answers"
import quizInstances from "./modules/quizzes/instances"
import paperSheets from "./modules/quizzes/paper_sheets"
import questions from "./modules/quizzes/questions"
import appNotifications from "./modules/app_notifications"
import quizProctoringImages from "./modules/quizzes/proctoring/images"
import quizProctoringImagesAnalysis from "./modules/quizzes/proctoring/images_analysis"
import quizStudentEvents from "./modules/quizzes/student_events"
import moodleCourses from "./modules/moodle/moodle_courses"
import moodleCategories from "./modules/moodle/moodle_categories"
import quizProctoringImagesAnalysisStats from "./modules/quizzes/proctoring/images_analysis_stats"
import quizTags from "./modules/quizzes/tags"
import proctoring from "./modules/quizzes/proctoring"
import quizzesDocuments from "./modules/quizzes/documents"
import emails from "./modules/emails"
import schools from "./modules/schools"
import questionsTags from "./modules/quizzes/questions_tags"
import questionsTagsCategory from "./modules/quizzes/questions_tags category"
import channels from "./modules/quizzes/chat/channels"
import messages from "./modules/quizzes/chat/messages"
import taskProgressManagers from "./modules/quizzes/task_progress_managers"
import ProctoringImagesV2 from "./modules/quizzes/proctoring/images_v2"
import quizzesSubscriptions from "./modules/quizzes/subscriptions"
import quizzesGroups from "./modules/quizzes/groups"
import schoolsUsers from "./modules/schools_users"
import SimilarityCheckSubmissions from "./modules/quizzes/similarity_check_submissions"
import QuizzesStats from "./modules/quizzes/stats"
import TimeAccommodations from "@/store/modules/time_accommodations"
import TimeAccommodationSummaries from "@/store/modules/quizzes/time_accommodation_summaries"
import AccessibilityOptions from "@/store/modules/accessibility_options"
import QuizSessions from "@/store/modules/quizzes/sessions"
import PaperQuizzes from "@/store/modules/quizzes/paper"
import QuizzesAttemptSummaries from "@/store/modules/quizzes/attempt_summaries"
import quizzesPublicSessions from "@/store/modules/quizzes/public_sessions"

Vue.use(Vuex)

const store = new Vuex.Store({
  actions,
  getters,
  modules: {
    currentUser,
    quizzes,
    quizDraft,
    scores,
    users,
    groups,
    questionsStats,
    scannedSheets,
    scans,
    answers,
    quizInstances,
    questions,
    appNotifications,
    quizProctoringImages,
    quizProctoringImagesAnalysis,
    quizStudentEvents,
    moodleCourses,
    moodleCategories,
    quizProctoringImagesAnalysisStats,
    quizTags,
    proctoring,
    quizzesDocuments,
    emails,
    schools,
    paperSheets,
    questionsTags,
    questionsTagsCategory,
    channels,
    messages,
    taskProgressManagers,
    ProctoringImagesV2,
    quizzesSubscriptions,
    quizzesGroups,
    schoolsUsers,
    SimilarityCheckSubmissions,
    QuizzesStats,
    TimeAccommodations,
    TimeAccommodationSummaries,
    AccessibilityOptions,
    QuizSessions,
    PaperQuizzes,
    quizzesAttemptSummaries: QuizzesAttemptSummaries,
    quizzesPublicSessions,
  },
})

export default store
export const useStore = () => store
